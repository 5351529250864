import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormatarRecuperacaoSenha } from 'src/app/shared/utils/formatar-recuperacao-senha';
import { environment } from 'src/environments/environment';
import { Compra } from '../../pedidos/shared/compras';
import { Cliente } from './cliente';
import { ClientePreferences } from './cliente-preferences';
import ClienteResponseDTO from './ClienteResponseDTO';

@Injectable({
    providedIn: 'root',
})
export class ClienteService {

    public service = '/clientes';
    public codigo_indicacao = '';
    public informacoes: any = null;

    public cadastro_incompleto = false;
    public cliente$ = null;
    public informacoes_cashback = null;

    public nome: string;
    public preferences: ClientePreferences = {};
    public changePreferences$: Subject<any> = new Subject();
    public afterLogin$: Subject<any> = new Subject();
    public afterLogout$: Subject<any> = new Subject();

    private autorizadoUsarCookie: boolean = null;
    private readonly STORAGE_NAME_LGPD_COOKIE = 'lgpd-aceite-cookie';

    private _cliente: Cliente = {};

    public get cliente(): Cliente {
        return this._cliente;
    }
    constructor(
        private localStorageService: LocalStorageService,
        private httpClient: HttpClient,
    ) {
        const segundos = parseFloat(this.localStorageService.get('date'));
        const lastLogin = new Date();
        lastLogin.setTime(segundos);

        function monthDiff(d1, d2) {
            let months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth() + 1;
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }

        if (monthDiff(lastLogin, new Date()) >= 1) {
            this.localStorageService.remove('token');
            this.localStorageService.remove('cliente');
            this.localStorageService.remove('pedido');
            window.dispatchEvent(new Event('atualizarCliente'));
            setTimeout(() => {
                window.dispatchEvent(new Event('atualizarCarrinhoCliente'));
            }, 100);
        }

        this.nome = this.localStorageService.get('nome');
        this.setAutorizadoUsarCookie();
    }

    saveCliente(cliente: Cliente) {
        this._cliente = cliente;
    }

    login(data: Cliente) {
        const URI = `filial/${environment.filialId}/logar`;
        return this.httpClient
            .post(`${environment.apiUrl}/loja/${URI}`, data)
            .toPromise()
            .then(async (resp: any) => {
                return this.validarAutenticacao(resp);
            });
    }

    async autenticar(token: string) {
        this.savarDataParaComparacaoDoUltimoLogin();
        this.salvarToken(token);
        this.getInformacaoGeralCliente();
        await this.getDescricaoCliente();
        this.afterLogin$.next();
    }

    validarAutenticacao(resp: any) {
        if (!resp.success) {
            alert('Login inválido. Verifique o usuário e a senha e tente novamente.');
            return false;
        }
        const token = resp.data;
        this.savarDataParaComparacaoDoUltimoLogin();
        this.salvarToken(token);
        this.getInformacaoGeralCliente();

        return this.getDescricaoCliente().then(() => {
            this.afterLogin$.next();
            return true;
        });
    }

    private salvarToken(token: string) {
        this.localStorageService.set('token', token);
    }

    private savarDataParaComparacaoDoUltimoLogin() {
        this.localStorageService.set('date', new Date().getTime());
    }

    private setNomeCliente(nome: string) {
        this.nome = nome;
    }

    private salvarNomeCliente(nome: string) {
        this.localStorageService.set('nome', nome);
    }

    logout() {
        return this.httpClient
            .post(this.service + '/logout', [])
            .toPromise()
            .then(() => {
                this.cliente$ = null;
                this._resetInformacoesCarrinho();
            });
    }

    logged() {
        return this.localStorageService.get('token') !== null;
    }

    eTeleVendas() {
        return !!this.localStorageService.get('operador');
    }

    async add(data: Cliente): Promise<ClienteResponseDTO> {
        return this.httpClient
            .post<ClienteResponseDTO>(
                `${environment.apiUrl}/loja/clientes/add/${environment.filialId}`,
                data
            )
            .toPromise();
    }

    edit(data: Cliente) {
        return this.httpClient.post(this.service + '/edit/' + data.id, data);
    }

    alterarSenha(data: Cliente) {
        return this.httpClient.post(
            this.service + '/alterar-senha/' + data.id,
            data
        );
    }

    alterarLogin(data: Cliente) {
        return this.httpClient.post(
            this.service + '/alterar-login/' + data.id,
            data
        );
    }

    recuperarSenha(data: any) {
        return this.httpClient.post(
            this.service + '/recuperar-senha/' + environment.filialId,
            (data = FormatarRecuperacaoSenha.data(data))
        );
    }

    redefinirSenha(chave: string) {
        return this.httpClient.get(this.service + '/redefinir-senha/' + chave);
    }

    redefinirSenhaConfirm(chave: string, data: any) {
        return this.httpClient.post(
            this.service + '/redefinir-senha/' + chave,
            data
        );
    }

    confirmarCodigo(codigo: string) {
        return this.httpClient.post(
            this.service + '/confirmar-codigo/' + environment.filialId + '/' + codigo,
            { codigo }
        );
    }

    getPedidoEmProcesso() {
        return this.httpClient.get(
            this.service + '/pedido-em-processo/' + environment.filialId
        );
    }

    setPedidoEmProcesso(pedido: Compra) {
        this.localStorageService.set('pedido', pedido);
    }

    confirmarToken(token: string) {
        return this.httpClient.post(
            this.service + '/confirmar-token/' + environment.filialId + '/' + token,
            { token }
        );
    }

    private _resetInformacoesCarrinho() {
        if (!!this.localStorageService.get('operador')) {
            this.localStorageService.remove('token');
            this.localStorageService.remove('cliente');
        } else {
            this.localStorageService.clearAll('^((?!(loja|auth|lgpd|organizacao)).)*$');
        }
        this.afterLogout$.next();
        window.dispatchEvent(new Event('atualizarCarrinho'));
    }

    recebimentoNewsletter(email: string) {
        return this.httpClient.post(
            this.service + '/recebimento-newsletters/' + environment.filialId,
            { email }
        );
    }

    editPreference(preferences) {
        if (this.cliente) {
            this.localStorageService.set('preferences', preferences);
            this.changePreferences$.next(preferences);
        }
    }

    getPreferences(): ClientePreferences {
        this.preferences = this.localStorageService.get('preferences') || {};
        return this.preferences;
    }

    permitirCadastro(cep: string) {
        const URL = `loja/clientes/cadastro/filial/${environment.filialId}/cep/${cep}/permitir`;
        return this.httpClient.get(`${environment.apiUrl}/${URL}`);
    }

    getInformacoes(): Promise<any> {
        if (this.informacoes != null && this.informacoes.success) {
            return Promise.resolve(this.informacoes);
        }

        return this.httpClient
            .get(`${environment.apiUrl}/loja${this.service}/informacoes`)
            .toPromise()
            .then((response: any) => {
                this.informacoes = response.data;
                return response.data;
            });
    }

    getInformacaoGeralCliente() {
        if (!this.cliente$) {
            this.httpClient
                .get(`${environment.apiUrl}/loja/cliente/index`)
                .subscribe((res: any) => {
                    this.saveCliente(res.data);
                    this.cliente$ = of(res.data);
                });
        }
    }

    getDescricaoCliente() {
        return this.httpClient
            .get(`${environment.apiUrl}/loja/cliente/descricao`)
            .toPromise()
            .then((resp: any) => {
                const nome = resp.data.descricao;
                this.setNomeCliente(nome);
                this.salvarNomeCliente(nome);
            });
    }

    getInformacoesClienteCompletarCadastro(): Promise<any> {
        return this.httpClient
            .get(`${environment.apiUrl}/loja/cliente/index`)
            .pipe(
                map((response: any) => response.data)
            )
            .toPromise();
    }

    getClienteEnderecoCompletarCadastro(): Promise<any> {
        return this.httpClient.get(`${environment.apiUrl}/loja/clientes/enderecos`)
            .pipe(
                map((response: any) => response.data[0])
            )
            .toPromise();
    }

    aceitarUsoCookie() {
        if (this.logged()) {
            this.httpClient
                .put(`${environment.apiUrl}/loja/clientes/aceite_cookies`, {})
                .toPromise();
        }
    }

    salvarAutorizacaoUsoCookie(autorizado: boolean = true) {
        this.localStorageService.set(this.STORAGE_NAME_LGPD_COOKIE, autorizado);
    }

    buscarAceiteUsoCookie() {
        return this.httpClient.get(
            `${environment.apiUrl}/loja/clientes/aceite_cookies`
        );
    }

    setAutorizadoUsarCookie() {
        this.autorizadoUsarCookie =
            this.localStorageService.get(this.STORAGE_NAME_LGPD_COOKIE) || false;
    }

    getAutorizadoUsarCookieLocal(): boolean {
        return (
            this.autorizadoUsarCookie ||
            this.localStorageService.get(this.STORAGE_NAME_LGPD_COOKIE) ||
            false
        );
    }

    public buscarSessaoCliente() {
        if (!this.getSessaoCliente()) {
            return this.httpClient.get(`${environment.apiUrl}/loja/sessao_cliente`)
                .toPromise()
                .then((res: any) => {
                    this.setSessaoCliente(res.data.sessao_id);
                });
        }
    }

    private setSessaoCliente(sessao) {
        this.localStorageService.set('sessao', sessao);
    }

    public getSessaoCliente() {
        return this.localStorageService.get('sessao');
    }

    getCartoesSalvos(cdId: number, tipoEntregaId: number) {
        return this.httpClient
            .get(`${environment.wsUrl}/clientes/cartoes/filial/${environment.filialId}/centro_distribuicao/${cdId}/tipo_entrega/${tipoEntregaId}`)
            .pipe(
                map((res: any) => res.cartoes));
    }

    excluirCartao(id: string) {
        return this.httpClient.delete(`${environment.wsUrl}/clientes/cartoes/${id}`);
    }

    async solicitarSms(telefoneCelular: string) {
        const URI = `clientes/filial/${environment.filialId}/solicitar_sms_pin`;
        const response = await this.httpClient.post(`${environment.apiUrl}/loja/${URI}`, {
            telefone_celular: telefoneCelular
        })
            .toPromise()
        return response;
    }

    async consultaSolicitacaoSms() {
        const URI = 'clientes/otp';
        const response = await this.httpClient.get<{
            data: {
                telefone_celular: string,
                expire_at: string,
                telefone_celular_validado: boolean
            }
        }>(`${environment.apiUrl}/loja/${URI}`)
            .toPromise()
        return response.data;
    }

    async validarOtp(code: string) {
        const URI = 'clientes/otp/validar';
        const response = await this.httpClient.post<{
            data: {
                valido: boolean
            }
        }>(`${environment.apiUrl}/loja/${URI}`, {
            token: code
        })
            .toPromise()
        return response
    }

    async invalidarUsuarioOtp(telefone_celular: string) {
        const URI = 'clientes/otp/invalidar';
        const response = await this.httpClient.post(`${environment.apiUrl}/loja/${URI}`, {
            telefone_celular
        }).toPromise()
        return response;
    }

}
